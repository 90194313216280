Vue.component('vue-nav-dropdown', {
    delimiters: ['${', '}'],

    template: '#vue-nav-dropdown-template',

    props: ['label', 'items', 'href'],

    data() {
        return {
            dropdown_open: false,
        }
    },

    methods: {
        toggleDropdown() {
            this.dropdown_open = ! this.dropdown_open;
        },
        showDropdown() {
            this.dropdown_open = true;
        },
        hideDropdown(e) {
            this.dropdown_open = false;
        },
        clickOutside() {
            if (this.$el !== event.target && ! this.$el.contains(event.target)) {
                this.dropdown_open = false;
            }
        },
    },

    created () {
        document.addEventListener('click', this.clickOutside);
    },

    destroyed () {
        document.removeEventListener('click', this.clickOutside);
    },
});
